import { useQuery, gql } from '@apollo/client'

export interface Article {
  id: string
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any
}

interface ArticleCollectionQueryVars {
  limit: number
  skip: number
}

interface ArticleCollectionQueryData {
  articleCollection?: {
    items: Article[]
  }
}

const ARTICLES_PER_PAGE = 8
export const getArticleVariables = (page: number) => {
  const skip = (page - 1) * ARTICLES_PER_PAGE
  return {
    skip,
    limit: ARTICLES_PER_PAGE,
  }
}
export const getNumberOfPages = (numberOfGalleries: number) =>
  Math.ceil(numberOfGalleries / ARTICLES_PER_PAGE)

const QUERY = gql`
  query ArticleCollection($limit: Int!, $skip: Int!) {
    articleCollection(
      limit: $limit
      skip: $skip
      order: sys_firstPublishedAt_DESC
    ) {
      items {
        title
        content {
          json
          links {
            assets {
              block {
                sys {
                  id
                }
                url
                title
                width
                height
                description
                contentType
              }
            }
          }
        }
      }
    }
  }
`

const useGetArticles = ({ skip, limit }: ArticleCollectionQueryVars) =>
  useQuery<ArticleCollectionQueryData, ArticleCollectionQueryVars>(QUERY, {
    variables: { skip, limit },
  })

export default useGetArticles
