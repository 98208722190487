import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderOptions = (links: any, callback: (id: string) => void) => {
  // create an asset map
  const assetMap = new Map()

  // loop through the linked assets and add them to a map
  for (const asset of links.assets.block) {
    assetMap.set(asset.sys.id, asset)
  }

  return {
    renderNode: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const asset = assetMap.get(node.data.target.sys.id)

        switch (asset.contentType) {
          case 'video/mp4':
            return (
              <video width="100%" height="100%" controls>
                <source src={asset.url} type="video/mp4" />
              </video>
            )
          case 'image/png':
          case 'image/jpeg':
            return (
              <img
                src={asset.url}
                height={asset.height}
                width={asset.width}
                alt={asset.description}
                onClick={() => callback(asset.sys.id)}
                className="hover:cursor-pointer max-w-full lg:max-w-[230px] h-auto mr-2"
              />
            )
          default:
            return 'Nothing to see here...'
        }
      },
    },
  }
}

export const renderRichText = (
  json: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  links: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  callback: (id: string) => void
) => {
  return documentToReactComponents(json, renderOptions(links, callback))
}
