import cn from 'classnames'
import { renderRichText } from '../api/helpers'
import Carousel, { Modal, ModalGateway } from 'react-images'
import { useState } from 'react'

interface MappedPhoto {
  src: string
  id: string
}

interface Props {
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any
  isLast?: boolean
}

const Article = ({ title, isLast, content }: Props) => {
  const [currentImage, setCurrentImage] = useState('')
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  const photos = content.links.assets.block.filter(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (asset: any) =>
      asset.contentType === 'image/png' || asset.contentType === 'image/jpeg'
  )
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const mappedPhotos: MappedPhoto[] = photos.map((photo: any) => ({
    src: photo.url,
    id: photo.sys.id,
  }))

  const openLightbox = (id: string) => {
    setCurrentImage(id)
    setViewerIsOpen(true)
  }

  const closeLightbox = () => {
    setCurrentImage('')
    setViewerIsOpen(false)
  }

  return (
    <div className={cn('pb-4 mb-8', { divider: !isLast, 'pb-0 mb-0': isLast })}>
      <h3 className="text-medium text-main text-28 mb-4">{title}</h3>
      <div className="p1 mb-0 mt-0">
        {renderRichText(content.json, content.links, openLightbox)}
      </div>
      <ModalGateway>
        {viewerIsOpen && (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={mappedPhotos.findIndex(
                (photo) => photo.id === currentImage
              )}
              views={mappedPhotos.map((photo) => ({
                source: {
                  regular: photo.src,
                },
              }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </div>
  )
}

export default Article
