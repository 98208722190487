import { useQuery, gql } from '@apollo/client'

interface ArticleCollectionQueryData {
  articleCollection: {
    total: number
  }
}

const QUERY = gql`
  query {
    articleCollection {
      total
    }
  }
`
const useGetArticlesCount = () => useQuery<ArticleCollectionQueryData>(QUERY)

export default useGetArticlesCount
