import useGetArticles, {
  getNumberOfPages,
  getArticleVariables,
} from '../api/useGetArticles'
import useGetArticlesCount from '../api/useGetArticlesCount'

const useArticles = (page: number, limit?: number) => {
  const { skip, limit: defaultLimit } = getArticleVariables(page)
  const { data } = useGetArticles({ skip, limit: limit ?? defaultLimit })
  const { data: articlesCountData } = useGetArticlesCount()
  const articles = data?.articleCollection?.items ?? []

  const numberOfPages = getNumberOfPages(
    articlesCountData?.articleCollection.total ?? 0
  )

  return {
    articles,
    numberOfPages,
  }
}

export default useArticles
